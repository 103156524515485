import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_CONTESTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_PAPERS, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_STORE_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_USERPROJECTS, DATA_TYPE_GOLDENSUMMIT_CATEGORIES, // DATA_TYPE_USERPROJECT_CATEGORIES,
DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_NEWPRODUCT_CATEGORIES } from 'data/config/dataConfig';
import config from 'data/config/config';
import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY, // LOGIN_PAGE_KEY,
MOBIGEO_PAGE_KEY, GOOGLE_MAP_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, INBOX_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil'; //import showPdf from 'src/core/pdf/pdfViewerAndroid';

import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import * as Query from 'src/core/query/Query';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
import { isAndroid } from 'src/core/util/browser';
var LOG_PREF = '[homeConfig] ';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE,
    VISITOR_PROFILE = _require.VISITOR_PROFILE,
    EXHIBITOR_PROFILE = _require.EXHIBITOR_PROFILE,
    WEBAPP_EXH_PROFILE = _require.WEBAPP_EXH_PROFILE,
    WEBAPP_EVT_PROFILE = _require.WEBAPP_EVT_PROFILE,
    WEBAPP_SPK_PROFILE = _require.WEBAPP_SPK_PROFILE; // NB: used for ChooseProfile page too !


export var getStyle = function getStyle() {
  return {
    background: "url(".concat(getUrl('files/project/home/home-background.jpg'), ") no-repeat"),
    backgroundSize: 'contain'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 2,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 170,
  // 2 columns mode => less width available
  portrait: 230
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.65,
  // rowspan
  width: 1 // colspan

}; // const tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))'

function getPdf() {
  return createItem( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Actualites.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.pdf2', // Action to perform on click
  function (actions) {
    var url = 'https://www.ets.org/Media/Tests/TFI/pdf/TFI_Sample_Test.pdf';

    if (isAndroid()) {
      showPdf(url);
    } else if (openUrl(url, null, '_blank', true)) {
      actions.navigate(url);
    }
  }, GENERAL_TILE_OPTIONS);
}

function getPdf2() {
  return createItem( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Actualites.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.pdf', // Action to perform on click
  function (actions) {
    var url = 'https://www.ets.org/Media/Tests/TFI/pdf/TFI_Sample_Test.pdf';

    if (openUrl(url, null, isAndroid() ? '_system' : '_blank', isAndroid() ? false : true)) {
      actions.navigate(url);
    }
  }, GENERAL_TILE_OPTIONS);
}

var getServiceIdByLumpMainId = function getServiceIdByLumpMainId(title, dataType) {
  var items = Query.getTopCategories(dataType);
  var parentId;
  items && items.map(function (item) {
    if (item.title === title) {
      parentId = item.id;
    }
  });
  return parentId;
};

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  homebutton["label_".concat(lang)], // Action to perform on click
  function (actions) {
    var url = homebutton["link_".concat(lang)];
    var target = homebutton.target;

    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

function getParticipantsTile() {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }

  var isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/conferences.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.miseEnRelation', // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  }, // Tile options
  GENERAL_TILE_OPTIONS);
}

var getEventsTile = function getEventsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/programme.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.programme', // Page & props
  // LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  LIST_PAGE_KEY, {
    inputs: [{
      id: getServiceIdByLumpMainId('Programme', DATA_TYPE_EVENT_CATEGORIES),
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getServicesTile = function getServicesTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/services.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.services', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      parentId: getServiceIdByLumpMainId('Services & Contacts', DATA_TYPE_SERVICE_CATEGORIES),
      parentType: DATA_TYPE_SERVICE_CATEGORIES,
      dataType: DATA_TYPE_SERVICES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getExhibitorsTile = function getExhibitorsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/exposants.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.exhibitors', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getAboutTile = function getAboutTile() {
  return (// ABOUT CIGRE
    createItemRoute( // Style
    {
      container: {
        className: 'home-btn-text'
      },
      icon: {
        style: {
          backgroundImage: 'url(' + getUrl('files/project/home/about.svg') + ')'
        }
      }
    }, // Label (i18n)
    'home.about', // Page & props
    //
    //  TODO !
    //
    LIST_PAGE_KEY, {
      inputs: [{
        id: getServiceIdByLumpMainId('About CIGRE', DATA_TYPE_SERVICE_CATEGORIES),
        dataType: DATA_TYPE_SERVICE_CATEGORIES
      }]
    }, // Tile options
    GENERAL_TILE_OPTIONS)
  );
};

var getNewPapersTile = function getNewPapersTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/papers.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.papers', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_PAPER_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getSocialMediaTile = function getSocialMediaTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/polls.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.medias', // Page & props
  MEDIAS_PAGE_KEY, {
    /*   controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'twitter',
    }, */
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

function getEbadgeTile(lang) {
  return createItem( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        maskImage: "url(".concat(getUrl('icons-svg/badge.svg'), ")"),
        WebkitMaskImage: "url(".concat(getUrl('icons-svg/badge.svg'), ")"),
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskSize: '100%',
        backgroundColor: '#F39200',
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        maskSize: '100%',
        height: '1em',
        width: '2em',
        fontSize: '2em'
      }
    }
  }, // Label (i18n)
  'home.ebadge', // Action to perform on click
  function () {
    if (openUrl(config.BADGE_LINK[lang], null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.BADGE_LINK[lang]);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS
  /* {
      height:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation]
          ? 0.6
          : 0.9,
      width:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 2 : 1,
    } */
  );
}

var getMobigeoTile = function getMobigeoTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/plan.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.mobigeo', // Page & props
  MOBIGEO_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getGoogleMapTile = function getGoogleMapTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/planParis.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.googleMap', // Page & props
  GOOGLE_MAP_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getNotifications = function getNotifications() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.notifications', // Page & props
  INBOX_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getVideos = function getVideos() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.videos', // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'youtube' // to match index of social Media

    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {}
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 3,
      tiles: [
      /* //pdf externe
      isAndroid() && getPdf(),
      
      //pdf interne
       getPdf2(), */
      //
      // MarketPlace
      //getMarketPlaceTile(),
      // Programme
      getEventsTile(), // papers
      getNewPapersTile(), // PLAN
      getMobigeoTile(), // Exposants
      getExhibitorsTile(), //getEventsTile2(),
      // Speakers
      //getSpeakersTile(),
      //getGoogleMapTile(),
      //getStoresTile(),
      // Sommet d’or
      //getGoldenSummitTile(),
      // Concours Animaux
      //getContestsTile(),
      // Offres d’emploi
      //getJobOffersTile(),
      // Mise en relation
      //getParticipantsTile(),
      // Actualites
      //getNewsTile(lang),
      // INFOS PRATIQUES
      getServicesTile(), // about cigre
      getAboutTile(), // Badges
      //getEbadgeTile(lang),
      // Social Medias
      getSocialMediaTile() // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS

      /* hasAdButtons && adConfig.homebuttons.homebutton1
        ? createItemAd(adConfig.homebuttons.homebutton1, lang)
        : null, */
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  },
  /*  {
     component: MobilespotBar,
   }, */
  {
    component: IphonexFlexMargin
  }];
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */


export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  switch (profile) {
    // PRO
    case DEFAULT_PROFILE:
      return getDefault(lang, adConfig, orientation, isLoggedIn);
    // GRAND PUBLIC

    default:
      if (!profile) {
        console.warn("".concat(LOG_PREF, "No profile set yet"));
      } else {
        console.error("".concat(LOG_PREF, "Unsupported profile: ").concat(profile));
      }

  }
}