module.exports = require("deepmerge")(require("./config.default.js"), {
  native: {
    name: {
      default: "CIGRE 2022_D"
    },
    id: "com.mobilespot.cigre.dev",
    // iOS
    appleTeamId: "2BAP3P29V2",
    // iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ""
  },
  // which additional repos need to be cloned
  relatedRepos: [{
    src: "git@bitbucket.org:mobilespotdev/cordova.git",
    branch: "inte/cigre2022/main",
    postCommands: ["cd cordova && npm install"]
  }, {
    src: "git@bitbucket.org:mobilespotdev/mobigeo.git",
    branch: "inte/cigre2022/main",
    postCommands: ["cd mobigeo && npm install"]
  }, {
    src: "git@bitbucket.org:mobilespotdev/mobigeo-customs-cigre.git",
    branch: "inte/cigre2022/main",
    dest: "mobigeo/mobigeo-customs"
  }, {
    src: "git@bitbucket.org:mobilespotdev/app-react.git",
    branch: "inte/cigre2022/main"
  }, {
    src: "git@bitbucket.org:mobilespotdev/data-cigre-2022.git",
    branch: "inte/cigre2022/main",
    dest: "app-react/data",
    postCommands: ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }],
  web: {
    url: "cigre2022.ms-dev.mobile-spot.com"
  }
});