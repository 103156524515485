import _defineProperty from "/Users/mohamedbenkhedher/Documents/react/mspot-cigre-2022/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _PAGES_SETUP;

/**
 *
 * Configuration describing how 2 pages are displayed simultaneously, screen being vertically split
 *
 */
import config from 'data/config/config';
import { // AIRCRAFT_PAGE_KEY,
EVENT_PAGE_KEY, EXHIBITOR_PAGE_KEY, PAPER_PAGE_KEY, LIST_PAGE_KEY, //MAP_PAGE_KEY,
// RESTAURANT_PAGE_KEY,
SERVICE_PAGE_KEY } from 'src/pages/pagesKeys.js';
import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_PAPER_CATEGORIES } from 'data/config/dataConfig';
export var FEATURE_ACTIVATED = true;
/**
 * Required min width to auto-enable this mode
 * @type {Number}
 */

export var MIN_WIDTH = 800;
export var AREA = {
  right: 'right',
  left: 'left'
};
/**
 * Default area for all pages
 */

export var TWO_COLUMNS_DEFAULT = AREA.left;
/**
 * Every page NOT declared here will rely on `TWO_COLUMNS_DEFAULT`
 */

export var PAGES_SETUP = (_PAGES_SETUP = {}, _defineProperty(_PAGES_SETUP, EVENT_PAGE_KEY, AREA.right), _defineProperty(_PAGES_SETUP, EXHIBITOR_PAGE_KEY, AREA.right), _defineProperty(_PAGES_SETUP, config.MAP.DEFAULT_MAP_PAGE_KEY, AREA.right), _defineProperty(_PAGES_SETUP, SERVICE_PAGE_KEY, AREA.right), _defineProperty(_PAGES_SETUP, PAPER_PAGE_KEY, AREA.right), _PAGES_SETUP);
/**
 * Indicate in which columns a page should be displayed
 * @param  {string} pageKey
 * @return {string}
 * @see    pagesTwoColumnsConfig.AREA
 */

export var getArea = function getArea(pageKey) {
  return PAGES_SETUP[pageKey] || TWO_COLUMNS_DEFAULT;
};
/**
 * Toolbar is displayed on a single area. Define here on which one
 */

export var TOOLBAR_AREA = AREA.left;
/**
 * CONFIGURE THIS FOR EVERY PROJECT
 */

export function getSecondPage(displayedPageKey, displayedPageProps, profile) {
  var displayedPageArea = getArea(displayedPageKey); // If displayed page is on left side, then display Map on the right side

  if (displayedPageArea === AREA.left) {
    return {
      pageKey: config.MAP.DEFAULT_MAP_PAGE_KEY,
      props: null
    };
  } else {
    switch (displayedPageKey) {
      case EVENT_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_EVENT_CATEGORIES
            }]
          }
        };

      case EXHIBITOR_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
            }]
          }
        };

      case PAPER_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_PAPER_CATEGORIES
            }]
          }
        };

      case SERVICE_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_SERVICE_CATEGORIES
            }]
          }
        };

      case config.MAP.DEFAULT_MAP_PAGE_KEY:
        return config.getHomePage(profile);
    }
  }
}
/**
 * Declare fiche page keys for which POI should be automatically displayed on map
 * @type {Array}
 */

export var AUTOSHOW_POI_ON_MAP = {// [AIRCRAFT_PAGE_KEY]  : true,
  // [EVENT_PAGE_KEY]     : true,
  // [EXHIBITOR_PAGE_KEY] : true,
  // [RESTAURANT_PAGE_KEY]: true,
  // [SERVICE_PAGE_KEY]   : true,
};