module.exports = {
  name: "cigre",
  client: "pro4events",
  description: "App for CIGRE 2022 event",
  validLangs: ["en"],
  version: {
    mainVersion: "4.0.2",
    buildVersion: {
      iOS: "40",
      android: "41",
      web: "41"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosBuildTarget: "11.0",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    androidSigning: {
      keyAlias: "cigre",
      keyStorePassword: "ahsahzaiye1Nooxo"
    },
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  crypto: false,
  web: {}
};